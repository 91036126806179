import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuid } from 'uuid'

// Library Components
import { Space } from 'antd'

// Assets
import FilterIcon from 'assets/images/filter.svg'
import ArrowDownIcon from 'assets/images/arrow-down.svg'

// Styled Elements
import {
  AccordionTableWrapper,
  AccordionTableHeaderWrapper,
  AccordionHeaderTitleWrapper,
  AccordionHeaderIcon,
  AccordionHeaderTitle,
  AccordionHeaderFilterButton,
  AccordionFilterWrapper,
  AccordionTableBodyOverflowWrapper,
  AccordionTableBodyWrapper,
  AccordionTableBodyHeaderWrapper,
  AccordionTableBodyHeaderItem,
  AccordionTableBodyHeaderText,
  AccordionTableBodySubHeaderText,
  AccordionTableBodyHeaderIcon,
  AccordionHeaderOptionsWrapper,
} from './AccordionTable.elements'

// Views
import { Image, IconButton, Pagination } from 'views/components'

const AccordionTable = (props) => {
  // Destructure
  const {
    title,
    titleIcon,
    headers,
    headerOptions,
    defaultSortOptions,
    options,
    children,
    minWidth,
    totalItems,
    filterComponents,
    onChange,
  } = props

  // States
  const [collapseFilter, setCollapseFilter] = useState(true)
  const [sortDirection, setSortDirection] = useState(defaultSortOptions.sortDirection || 'ASC')
  const [sortBy, setSortBy] = useState(defaultSortOptions.sortBy || '')
  const [pageIndex, setPageIndex] = useState(1)
  const [pageSize, setPageSize] = useState(10)

  // Variables
  const withHeader = headers.length
  const isHeaderSortActive = ({ identifier }) => identifier === sortBy

  // Functions
  const handleCollapseFilter = () => {
    setCollapseFilter((prevState) => !prevState)
  }

  const handleSort = (args) => {
    const { identifier } = args

    const sortDirectionToggle = sortDirection === 'DESC' ? 'ASC' : 'DESC'

    if (sortBy !== identifier) {
      setSortBy(identifier)
      setSortDirection('ASC')
    } else {
      setSortDirection(sortDirectionToggle)
    }
  }

  const handlePageChange = ({ pageIndex, pageSize }) => {
    setPageIndex(pageIndex)
    setPageSize(pageSize)
  }

  // useEffect
  useEffect(() => {
    onChange({ sortBy, sortDirection, pageIndex, pageSize })
  }, [sortDirection, sortBy, pageIndex, pageSize])

  return (
    <>
      <AccordionTableWrapper allowZeroExpanded>
        <AccordionTableHeaderWrapper>
          <AccordionHeaderTitleWrapper>
            <AccordionHeaderIcon>{titleIcon}</AccordionHeaderIcon>
            <AccordionHeaderTitle>{title}</AccordionHeaderTitle>
          </AccordionHeaderTitleWrapper>
          <AccordionHeaderFilterButton>
            <Space>
              <IconButton onClick={handleCollapseFilter}>
                <Image src={FilterIcon} width={20} height={20} />
              </IconButton>
              {headerOptions}
            </Space>
          </AccordionHeaderFilterButton>
          <AccordionHeaderOptionsWrapper>{options}</AccordionHeaderOptionsWrapper>
        </AccordionTableHeaderWrapper>
        <AccordionFilterWrapper collapseFilter={collapseFilter} withFilter={filterComponents}>
          {filterComponents}
        </AccordionFilterWrapper>
        <AccordionTableBodyOverflowWrapper>
          <AccordionTableBodyWrapper minWidth={minWidth}>
            <AccordionTableBodyHeaderWrapper showHeader={withHeader}>
              {headers.map(({ name, subHeader, sort, identifier, width }) => (
                <AccordionTableBodyHeaderItem
                  key={uuid()}
                  sortable={sort}
                  width={width}
                  onClick={() => sort && handleSort({ identifier })}
                >
                  <AccordionTableBodyHeaderText>
                    {name}
                    {subHeader && <AccordionTableBodySubHeaderText>{subHeader}</AccordionTableBodySubHeaderText>}
                  </AccordionTableBodyHeaderText>

                  {sort && (
                    <AccordionTableBodyHeaderIcon
                      sortDirection={sortDirection}
                      active={isHeaderSortActive({ identifier })}
                    >
                      <Image src={ArrowDownIcon} width={18} height={18} />
                    </AccordionTableBodyHeaderIcon>
                  )}
                </AccordionTableBodyHeaderItem>
              ))}
            </AccordionTableBodyHeaderWrapper>
            {children}
          </AccordionTableBodyWrapper>
        </AccordionTableBodyOverflowWrapper>
      </AccordionTableWrapper>
      <Pagination
        itemsCountPerPage={10}
        totalItemsCount={totalItems}
        pageRangeDisplayed={3}
        onChange={handlePageChange}
      />
    </>
  )
}

// Default Props
AccordionTable.defaultProps = {
  title: '',
  titleIcon: '',
  headers: [],
  headerOptions: '',
  defaultSortOptions: {},
  options: '',
  children: '',
  minWidth: 0,
  totalItems: 0,
  filterComponents: '',
  onChange: () => {},
}

// Proptypes Validation
AccordionTable.propTypes = {
  title: PropTypes.string,
  titleIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  headers: PropTypes.instanceOf(Array),
  headerOptions: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  defaultSortOptions: PropTypes.instanceOf(Object),
  options: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  minWidth: PropTypes.number,
  totalItems: PropTypes.number,
  filterComponents: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  onChange: PropTypes.func,
}

export default AccordionTable
