import React from 'react'
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'
import { v4 as uuid } from 'uuid'

// Route Configurations
import Routes from 'common/config/route'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'

// Global Components
import GlobalComponents from 'views/global'

const Routing = () => {
  /// Check if user is logged in
  const [isLoggedIn] = useLocalStorage('isLoggedIn')
  const [userCredentials] = useLocalStorage('userCredentials')

  /**
   * Validates if current authenticated user is authorized to view a route
   * @param  {} roles
   */
  const isUserAuthorized = (roles) => {
    // If route has role restriction, check if user role is authorized
    if (roles && roles.length) roles.some((role) => role === userCredentials.role)

    // Else route has no restriction give user access
    return true
  }

  /**
   * Conditionally selects route/component to render based on authentication or authorization
   * @param  {} isRouteProtected
   * @param  {} routeRoleAccess
   * @param  {} component
   */
  const renderRouteComponent = (isRouteProtected, routeRoleAccess, component) => {
    // Render protected routes
    if (isRouteProtected && isLoggedIn && userCredentials.access_token && isUserAuthorized(routeRoleAccess)) {
      return (
        <>
          {React.createElement(component)}
          <GlobalComponents />
        </>
      )
    }

    // Render public routes
    if (!isRouteProtected && !isLoggedIn) {
      return (
        <>
          {React.createElement(component)}
          <GlobalComponents />
        </>
      )
    }

    // Render not found route
    if (isRouteProtected === undefined) return <Redirect to="/404" />

    // Redirect to home page if logged in user access public routes
    if (!isRouteProtected && isLoggedIn) {
      return (
        <>
          <Redirect to="/" />
          <GlobalComponents />
        </>
      )
    }

    // Redirect to login page if non logged in user access protected routes
    if (isRouteProtected && !isLoggedIn) {
      return (
        <>
          <Redirect to="/login" />
          <GlobalComponents />
        </>
      )
    }
  }

  return (
    <BrowserRouter>
      <Switch>
        {Routes.map(({ isRouteProtected, exact, roleAccess, routing: { path, component } }) => (
          <Route
            exact={exact}
            path={path}
            key={uuid()}
            render={() => renderRouteComponent(isRouteProtected, roleAccess, component)}
          />
        ))}
      </Switch>
    </BrowserRouter>
  )
}

export default Routing
