import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { v4 as uuid } from 'uuid'

// Assets
import PlusWhiteIcon from 'assets/images/plus-white.svg'
import UsersIcon from 'assets/images/users.svg'
import BriefCaseIcon from 'assets/images/briefcase.svg'

// Store
import { actions } from 'core/store'

// Hooks
import { useLocalStorage } from 'core/hooks/storage'
import { useFetchAllCases, useFetchCaseCustomer } from 'core/hooks/api'

// Styled Elements
import { CaseManagementTableWrapper, DropdownItemInnerText } from './CaseManagementTable.elements'

// Views
import {
  Table,
  Button,
  Image,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  EmptyTablePlaceholder,
} from 'views/components'

import { CaseManagementTableItem, CaseManagementTableFilterForm } from 'views/layouts'

// Map Redux Props
const mapStateToProps = (state) => state
const mapDispatchToProps = (dispatch) => actions(dispatch)

const CaseManagementTable = (props) => {
  // Destructure
  const { filters, actions, onChange } = props

  // Store States
  const { caseManagementFilters } = filters

  // Store Actions
  const {
    toggleCasesManagementFilterModal,
    openCreateIndividualCaseDrawer,
    openCreateEntityCaseDrawer,
    toggleChooseKYCUploadModal,
  } = actions

  // Variables
  const tableHeaders = [
    { name: 'Case Type', width: 130 },
    { name: 'Customer ID', width: 120 },
    { name: 'Status', width: 120 },
    { name: 'Transaction Monitoring', subHeader: '(Last 30 days)' },
    { name: 'Whitelisting Activity', subHeader: '(Last 30 days)' },
  ]

  // States
  const [casesData, setCasesData] = useState({ items: [], total: [0] })
  const [tableFilters, setTableFilters] = useState({})

  // Hooks
  const [userCredentials] = useLocalStorage('userCredentials', false)
  const { allCasesData, getAllCases } = useFetchAllCases()
  const { caseCustomerData, getCaseCustomer } = useFetchCaseCustomer()

  // Functions
  const handleOnTableFilterToggle = () => {
    if (window.innerWidth < 920) {
      toggleCasesManagementFilterModal()
    }
  }

  const handleOnTableChange = (params) => {
    const { sortBy, sortDirection, pageIndex, pageSize } = params

    setTableFilters((prevState) => ({ ...prevState, ...params }))

    getAllCases({
      biz_id: userCredentials.Business_ID,
      pagesize: pageSize,
      pageindex: pageIndex,
      sortcol: sortBy,
      sortdir: sortDirection,
      case_type_id: tableFilters.caseType,
      status_id: tableFilters.caseStatus,
    })
  }

  const handleOnTableFilterChange = (params) => {
    const { caseType, caseStatus } = params

    setTableFilters((prevState) => ({ ...prevState, ...params }))

    onChange(params)

    getAllCases({
      biz_id: userCredentials.Business_ID,
      pagesize: tableFilters.pageSize,
      pageindex: tableFilters.pageIndex,
      sortcol: tableFilters.sortBy,
      sortdir: tableFilters.sortDirection,
      case_type_id: caseType,
      status_id: caseStatus,
    })
  }

  const handleOnTableSearchFilterChange = (customerId) => {
    setTableFilters((prevState) => ({ ...prevState, customerId }))

    if (customerId) {
      getCaseCustomer({
        customer_id: customerId,
      })
    } else {
      getAllCases({
        biz_id: userCredentials.Business_ID,
        pagesize: tableFilters.pageSize,
        pageindex: tableFilters.pageIndex,
        sortcol: tableFilters.sortBy,
        sortdir: tableFilters.sortDirection,
        case_type_id: tableFilters.caseType,
        status_id: tableFilters.caseStatus,
      })
    }
  }

  const handleOpenCreateIndividualCaseDrawer = () => {
    openCreateIndividualCaseDrawer()
  }

  const handleOpenCreateEntityCaseDrawer = () => {
    openCreateEntityCaseDrawer()
  }

  const handleOpenChooseKYCUpload = () => {
    toggleChooseKYCUploadModal()
  }

  useEffect(() => {
    if (allCasesData) {
      if (tableFilters.customerId) {
        setCasesData(() => ({
          ...allCasesData,
          items: allCasesData.items.filter(({ Customer_ID }) => Customer_ID === tableFilters.customerId),
        }))
      } else {
        setCasesData(allCasesData)
      }
    }
  }, [allCasesData])

  useEffect(() => {
    if (caseCustomerData) {
      setCasesData(() => ({
        ...allCasesData,
        items: caseCustomerData.filter(({ Case_Type_ID, Status_ID }) => {
          const caseTypeMatch = tableFilters.caseType ? Case_Type_ID === tableFilters.caseType : true
          const caseStatusMatch = tableFilters.caseStatus ? Status_ID === tableFilters.caseStatus : true

          return caseTypeMatch && caseStatusMatch
        }),
      }))
    }
  }, [caseCustomerData])

  useEffect(() => {
    if (tableFilters.customerId !== caseManagementFilters.customerId) {
      if (caseManagementFilters.customerId) {
        getCaseCustomer({
          customer_id: caseManagementFilters.customerId,
        })
      } else {
        getAllCases({
          biz_id: userCredentials.Business_ID,
          pagesize: tableFilters.pageSize,
          pageindex: tableFilters.pageIndex,
          sortcol: tableFilters.sortBy,
          sortdir: tableFilters.sortDirection,
          case_type_id: tableFilters.caseType,
          status_id: tableFilters.caseStatus,
        })
      }
    }

    if (
      tableFilters.caseType !== caseManagementFilters.caseType ||
      tableFilters.caseStatus !== caseManagementFilters.caseStatus
    ) {
      getAllCases({
        biz_id: userCredentials.Business_ID,
        pagesize: tableFilters.pageSize,
        pageindex: tableFilters.pageIndex,
        sortcol: tableFilters.sortBy,
        sortdir: tableFilters.sortDirection,
        case_type_id: caseManagementFilters.caseType,
        status_id: caseManagementFilters.caseStatus,
      })
    }

    setTableFilters((prevState) => ({ ...prevState, ...caseManagementFilters }))
  }, [caseManagementFilters])

  useEffect(() => {
    getAllCases({
      biz_id: userCredentials.Business_ID,
      pagesize: 10,
      pageindex: 0,
      sortcol: 'AddressCount',
      sortdir: 'DESC',
    })
  }, [])

  return (
    <CaseManagementTableWrapper>
      <Table
        title="Case Table"
        headers={tableHeaders}
        defaultSortOptions={{
          sortBy: 'AddressCount',
          sortDirection: 'DESC',
        }}
        totalItems={casesData.total[0]}
        headerOptions={
          <>
            <Button variant="outlined" onClick={handleOpenChooseKYCUpload}>
              Import
            </Button>
            <UncontrolledDropdown direction="down">
              <DropdownToggle>
                <Button startIcon={<Image width={16} height={16} src={PlusWhiteIcon} />}>Create Case</Button>
              </DropdownToggle>
              <DropdownMenu left>
                <DropdownItem onClick={handleOpenCreateIndividualCaseDrawer}>
                  <Image src={UsersIcon} width={16} height={16} />
                  <DropdownItemInnerText>Individual Case</DropdownItemInnerText>
                </DropdownItem>
                <DropdownItem onClick={handleOpenCreateEntityCaseDrawer}>
                  <Image src={BriefCaseIcon} width={16} height={16} />
                  <DropdownItemInnerText>Entity Case</DropdownItemInnerText>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        }
        minWidth={900}
        filterComponents={
          <CaseManagementTableFilterForm
            onChange={handleOnTableFilterChange}
            onCustomerSearch={handleOnTableSearchFilterChange}
          />
        }
        onFilterToggle={handleOnTableFilterToggle}
        onChange={handleOnTableChange}
      >
        {casesData.items.length ? (
          casesData.items.map(({ Case_ID, Case_Type, Case_Status, Customer_ID, TxCount, AddressCount }) => (
            <CaseManagementTableItem
              key={uuid()}
              caseId={Case_ID}
              caseType={Case_Type}
              caseStatus={Case_Status}
              customerId={Customer_ID}
              transactionCount={TxCount}
              addressCount={AddressCount}
            />
          ))
        ) : (
          <EmptyTablePlaceholder />
        )}
      </Table>
    </CaseManagementTableWrapper>
  )
}

// Default Props
CaseManagementTable.defaultProps = {
  actions: {},
  filters: {},
  onChange: () => {},
}

// Proptypes Validation
CaseManagementTable.propTypes = {
  onChange: PropTypes.func,
  filters: PropTypes.shape({
    caseManagementFilters: PropTypes.instanceOf(Object),
  }),
  actions: PropTypes.shape({
    toggleCasesManagementFilterModal: PropTypes.func,
    openCreateIndividualCaseDrawer: PropTypes.func,
    openCreateEntityCaseDrawer: PropTypes.func,
    toggleChooseKYCUploadModal: PropTypes.func,
  }),
}

export default connect(mapStateToProps, mapDispatchToProps)(CaseManagementTable)
